import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Screen } from "../../../models/screen.model";
import { ScreenService } from "src/app/services/screen/screen.service";
import { CampaignService } from "src/app/services/campaign/campaign.service";

import {
  MqttService,
  IMqttServiceOptions,
  MQTT_SERVICE_OPTIONS,
  IMqttMessage,
  MqttConnectionState,
} from "ngx-mqtt";
import { Subscription } from "rxjs";

@Component({
  selector: "app-screen",
  templateUrl: "./screen.component.html",
  styleUrls: ["./screen.component.css"],
})
export class ScreenComponent implements OnInit {
  private subscription: Subscription;
  public message: string = "Mqtt message here!";

  form: FormGroup;
  form1: FormGroup;
  description: string;
  sId: string;
  action: string;
  row: any;
  data: any;
  result: any = 'Na to result';
  saveDisabled: boolean;
  saveGroupDisabled: boolean;
  screenGroupFrmCtrl = new FormControl();
  screenGroups: any;
  num_of_screenGroups: number;
  screenGroupsSelection: string[];
  id: any;
  screen: any;
  screenId: any;

  constructor(
    private fb: FormBuilder,
    private _mqttService: MqttService,
    private campaignService: CampaignService,
    private dialogRef: MatDialogRef<ScreenComponent>,
    private screenService: ScreenService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.action = data.action;
    this.screen = {
      name : "",
      address: "",
      phone: "",
      email: "",
      enabled: true,
      registered: false,
      deviceId: "",
      deviceSerial: "",
      registeredDate: "10/02/20",
      orientation: "horizontal",
      screenGroups: [],
    };
    if(data.row) this.screen = data.row;




    // console.log('this.screen  >>>>>>> ', data);
    this.data = data;
    
    if (this.action == "deleteGroup") {
        this.id = data.id;
        // console.log('Na to to id :', this.id);
    }
    if (this.action == "edit") {
      // this.sId = this.screen._id;
      // this.screenService.getScreenById(this.sId).subscribe((res) => {
      //   console.log(">>>>>>>>>>>> getScreenById : ", res);
      //   this.screen = res;
      //   this.sId = this.screen._id;
      //   delete this.screen._id;
      //   this.screenGroupsSelection = [];
      //   this.screen.screenGroups.forEach((element) => {
      //     console.log("screenGroups", element);
      //     this.screenGroupsSelection.push(element._id);
      //   });
      //   console.log("this.screen <<<<<<<<<< ", this.screen);
      //   console.log("this.sId <<<<<<<<<< ", this.sId);
      //   console.log("screenGroupsSelection >>", this.screenGroupsSelection);
      // });


    }
    this.form1 = fb.group({
      name: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.campaignService.getScreenGroups().subscribe(
      (res) => {
        this.screenGroups = res;
        this.num_of_screenGroups = this.screenGroups.length;
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
      }
    );

    if (this.action == "editGroup") {
      this.form1 = this.fb.group({
        name: this.data.row.name,
      });
    }

    if (this.action == "new") {
      const data = {
        address: "",
        phone: "",
        email: "",
        enabled: true,
        registered: false,
        deviceId: "",
        deviceSerial: "",
        registeredDate: "10/02/20",
        orientation: "horizontal",
        screenGroups: [],
      };
      this.screenService.addScreen(data).subscribe((res) => {
        // console.log("Meta to ADD :", res);

        this.sId = res._id;
        delete res._id;
        this.screen = res;

        // this.form = this.fb.group({
        //   name: res.name,
        //   address: res.address,
        //   phone: res.phone,
        //   email: res.email,
        //   enabled: res.enabled,
        //   deviceId: res.deviceId,
        //   deviceSerial: res.deviceSerial,
        //   registered: false,
        //   registerdate: res.registerdate,
        //   orientation: res.orientation,
        //   screenId: res.screenId,
        //   screenGroupId: res.screenGroupId,
        //   pin: res.pin,
        // });

        this.subscription = this._mqttService
        // .observe("smartsignage/" + res.pin)
        .observe("register/" + res.pin)
        .subscribe((message: IMqttMessage) => {
            this.message = message.payload.toString();
            // console.log(message.payload.toString());
            this.data = JSON.parse(message.payload.toString());
            // console.log(this.data.deviceId);
            // console.log(this.data.deviceSerial);

            // this.form.controls["deviceId"].setValue(this.data.deviceId);
            // this.form.controls["deviceSerial"].setValue(this.data.deviceSerial);
            this.screen.deviceId = this.data.deviceId;
            this.screen.deviceSerial = this.data.deviceSerial;

          });
      });
    }
    if (this.action == "edit") {
      // console.log("edit this.row >> ", this.screen);

      this.sId = this.screen._id;
      this.screenService.getScreenById(this.sId).subscribe((res) => {
        // console.log(">>>>>>>>>>>> getScreenById : ", res);
        this.screen = res;
        this.sId = this.screen._id;
        delete this.screen._id;
        this.screenGroupsSelection = [];
        this.screen.screenGroups.forEach((element) => {
          // console.log("screenGroups", element);
          this.screenGroupsSelection.push(element._id);
        });
        // console.log("this.screen <<<<<<<<<< ", this.screen);
        // console.log("this.sId <<<<<<<<<< ", this.sId);
        // console.log("screenGroupsSelection >>", this.screenGroupsSelection);
      });



      // this.sId = this.row._id;
      // this.screen = this.row;
      // delete this.screen._id;

      // this.form = this.fb.group({
      //   name: this.row.name,
      //   address: this.row.address,
      //   phone: this.row.phone,
      //   email: this.row.email,
      //   enabled: this.row.enabled,
      //   deviceId: this.row.deviceId,
      //   deviceSerial: this.row.deviceSerial,
      //   registered: this.row.registered,
      //   registerdate: this.row.registerdate,
      //   orientation: this.row.orientation,
      //   screenId: this.row.screenId,
      //   screenGroupId: this.row.screenGroupId,
      //   pin: this.row.pin
      // });
    }

    // if (this.action == "newGroup") {
    //   const data = {
    //     name: "New Group"
    //   };
    //   this.screenService.addScreenGroup(data).subscribe((res) => {
    //     console.log(res);
    //     this.sId = res._id;
    //     this.form = this.fb.group({
    //       name: res.name
    //     });

    //   });
    // }
  }

  save() {
    if (this.action == "editGroup") {
      const data = this.form1.value;
      // console.log("data >>>>> ", data);
      this.screenService
        .putScreenGroup(this.data.row._id, data)
        .subscribe((res) => {
          // console.log(res);
          this.sId = res._id;
          this.form1 = this.fb.group({
            name: res.name,
          });
          this.dialogRef.close();
        });
    }

    if (this.action == "newGroup") {
      const data = this.form1.value;

      this.screenService.addScreenGroup(data).subscribe((res) => {
        // console.log(res);
        this.sId = res._id;
        this.form1 = this.fb.group({
          name: res.name,
        });
        this.dialogRef.close();
      });
    }

    // console.log("******this.action*******", this.action);
    // console.log("******this.sId*******", this.sId);
    if (this.action == "new") {
      // this.form.controls["pin"].setValue(0);
      // this.form.controls["enabled"].setValue(false);
      // this.form.controls["registered"].setValue(true);
    }
    if (this.action == "new" || this.action == "edit") {
      // console.log("******this.screen*******", this.screen);

      this.screenGroupsSelection = [];
      this.screen.screenGroups.forEach((element) => {
        // console.log("screenGroups", element);
        this.screenGroupsSelection.push(element._id);
      });
      this.screen.screenGroups = this.screenGroupsSelection;
      // console.log(">>>>> screenGroups", this.screen.screenGroups);
      this.screenService.putScreen(this.sId, this.screen).subscribe((res) => {
        // console.log("ADD : ", res);

        if (this.action == "new") {
          this.data = {
            enabled: res.enabled,
            registered: true,
            screenId: res.screenId,
            screenGroups: res.screenGroups,
            businessId: sessionStorage.getItem("businessId")
          };
          this.unsafePublish(
            // "smartsignage/" + res.deviceId + "/change",
            "register/" + res.deviceId + "/change",
            JSON.stringify(this.data)
          );
        }

        if (this.action == "edit") {
          this.data = {
            enabled: res.enabled,
            registered: true,
            screenId: res.screenId,
            screenGroups: res.screenGroups,
            businessId: sessionStorage.getItem("businessId")
          };
          this.unsafePublish(
            // "smartsignage/" + res.deviceId + "/change",
            sessionStorage.getItem("businessId") + "/" + res.deviceId + "/change",
            JSON.stringify(this.data)
          );
        }


        this.dialogRef.close();
      });
    }
  }

  close() {
    if (this.action == "new") {
      this.screenService.deleteScreen(this.sId).subscribe((res) => {
        // console.log(res);
      });
      this.subscription.unsubscribe();
      this.dialogRef.close();
    }
    this.dialogRef.close();
  }

  deleteScreen(){
    this.data.res = "ok";
  }


  deleteScreenGroup() {
    this.data.res = "ok";

    // console.log(this.result);

    //this.screenService.deleteScreenGroup(this.id);
    // this.screenService.deleteScreenGroup(this.id).subscribe(res => {
    //   console.log('------------------');
    //   console.log(res);
    // },
    // error => {
    //   console.log(error);
    // }
    // );
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 2, retain: false });
  }

  changeScreenGroups(e) {
    // console.log("e >>> ", e);

    this.screen.screenGroups = [];
    e.forEach((element) => {
      this.screen.screenGroups.push(
        this.screenGroups.find((x) => x._id == element)
      );
    });
    // console.log("this.screen.screenGroups  >>> ", this.screen.screenGroups);
  }
}
