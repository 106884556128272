import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public lang: Subject<any>;

  constructor() {
    this.lang = new Subject();
  }

  isChangedLang(lang: string) {
    this.lang.next(lang);
  }

  getLanguage() {
    return localStorage.defaultLang;
  }

  setLanguage(lang: string) {
    localStorage.setItem('defaultLang', lang);
  }


}
