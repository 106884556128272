import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { CampaignService } from "src/app/services/campaign/campaign.service";
import {
  MqttService
} from "ngx-mqtt";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-instant',
  templateUrl: './instant.component.html',
  styleUrls: ['./instant.component.css']
})
export class InstantComponent implements OnInit {
  myGroup: FormGroup;
  myDiv: any;
  myValues: any;
  bussinessID: string;

  public selectedColor: string = 'color1';

  public color1: string = '#ffffff';
  public color2: string = '#ffffff';
  public color3: string = '#ffffff';

  public backgroundUrl : string;
  public myStyles : any ;
  public text1Style : any = {
    'fontSize.em': 3
  };
  public text2Style : any = {
    'fontSize.em': 3
  };
  public text3Style : any = {
    'fontSize.em': 3
  };

  screenGroups: any;
  selectedScreenGroup: any = '';
  num_of_screenGroups: number;

  constructor(
    private cpService: ColorPickerService, 
    private _mqttService: MqttService,
    private campaignService: CampaignService
    ) {
    this.myGroup = new FormGroup({
      screenGroupsFrmCtrl: new FormControl(),
      imageUrl: new FormControl(),
      textLine1: new FormControl(),
      textLine1Color: new FormControl(),
      textLine1Size: new FormControl(),
      textLine2: new FormControl(),
      textLine2Color: new FormControl(),
      textLine2Size: new FormControl(),
      textLine3: new FormControl(),
      textLine3Color: new FormControl(),
      textLine3Size: new FormControl(),
      textTop: new FormControl(),
      textLeft: new FormControl()
    });
   }

  ngOnInit() {

    this.campaignService.getScreenGroups().subscribe(
      (res) => {
        this.screenGroups = res;
        this.num_of_screenGroups = this.screenGroups.length;
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
      }
    );




    this.myDiv = document.getElementById("htmlContent");

    //localStorage.removeItem("myvalues");

    //this.myValues = localStorage.getItem("myvalues");

    // console.log("localstorage : ", localStorage.getItem("myvalues"));

    if (localStorage.getItem("myvalues") === null) {
      this.myValues = {
        act: "imageText",
        imageUrl:
          "https://res.cloudinary.com/dcrsbikfx/image/upload/v1583397787/1_TASO_ze5sev.jpg",
        textTop: "17%",
        textLeft: "30%",
        line1Txt: "WELCOME",
        line1Size: 5,
        line1Color: "#ffffff",
        line2Txt: "ΚΑΛΩΣ ΗΡΘΑΤΕ",
        line2Size: 5,
        line2Color: "#999999",
        line3Txt: "",
        line3Size: 4,
        line3Color: "#87c2e7",
      };
      // console.log("mesa :", this.myValues);
      localStorage.setItem("myvalues", JSON.stringify(this.myValues));
    }
    this.myValues = JSON.parse(localStorage.getItem("myvalues"));
    // console.log("exo :", this.myValues);

    this.setValues(this.myValues);

  }

  setValues(myValues) {
    this.myGroup.controls["imageUrl"].setValue(myValues.imageUrl);

    this.myGroup.controls["textLine1"].setValue(myValues.line1Txt);
    this.myGroup.controls["textLine2"].setValue(myValues.line2Txt);
    this.myGroup.controls["textLine3"].setValue(myValues.line3Txt);

    // this.myGroup.controls['textLine1Color'].setValue(myValues.textLine1Color);
    // this.myGroup.controls['textLine2Color'].setValue(myValues.textLine2Color);
    // this.myGroup.controls['textLine3Color'].setValue(myValues.textLine3Color);

    this.color1 = myValues.line1Color;
    this.color2 = myValues.line2Color;
    this.color3 = myValues.line3Color;

    this.myGroup.controls["textLine1Size"].setValue(myValues.line1Size);
    this.myGroup.controls["textLine2Size"].setValue(myValues.line2Size);
    this.myGroup.controls["textLine3Size"].setValue(myValues.line3Size);

    this.myGroup.controls["textTop"].setValue(myValues.textTop);
    this.myGroup.controls["textLeft"].setValue(myValues.textLeft);

    this.myDiv.style.backgroundImage = "url('" + myValues.imageUrl + "')";
  }

  sendMessage(myForm) {
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(myForm);
    // console.log(this.color1);
    // console.log(this.color2);
    // console.log(this.color3);
    this.myDiv.style.backgroundImage = "url('" + myForm.imageUrl + "')";

    let mess = {
      act: "imageText",
      imageUrl: myForm.imageUrl,
      textTop: myForm.textTop,
      textLeft: myForm.textLeft,
      line1Txt: myForm.textLine1,
      line1Size: myForm.textLine1Size,
      line1Color: this.color1,
      line2Txt: myForm.textLine2,
      line2Size: myForm.textLine2Size,
      line2Color: this.color2,
      line3Txt: myForm.textLine3,
      line3Size: myForm.textLine3Size,
      line3Color: this.color3,
    };

    // console.log(JSON.stringify(mess));
    //this.unsafePublish('smartsignage/inst1357', JSON.stringify(mess));
    this.unsafePublish( sessionStorage.getItem("businessId")+"/"+this.selectedScreenGroup, JSON.stringify(mess));
    localStorage.setItem("myvalues", JSON.stringify(mess));
  }

  public onEventLog(event: string, data: any): void {
    // console.log(event, data);
  }

  public onChangeColor(color: string): void {
    // console.log("Color changed:", color);
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 0, retain: false });
  }



  changeScreenGroups(e) {
    //console.log(e);
    this.selectedScreenGroup = e;
  }


}
