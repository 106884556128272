import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AdsList } from 'src/app/models/ad.model';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AdService {
  // url: string = 'https://coffeelabsignage.herokuapp.com/api/';
  apiUrl: string = environment.general_url;

  constructor(
    private http: HttpClient
  ) { }


  getDisplayList(): Observable<AdsList> {

    return this.http.get<AdsList>(this.apiUrl+"/playlist",  { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }})
    .pipe(
      catchError(this.handleError)
    );
  }

  putDisplay(id,ad: any): Observable<any> {
    // console.log('id sto put',id);
    let data = JSON.stringify(ad);
    return this.http.put<any>(this.apiUrl+"/playlist/"+id, data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  deletePlaylist(id): Observable<any> {

    return this.http.delete<any>(this.apiUrl+"/playlist/"+id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }


  addPlaylist(ad:any): Observable<any> {
    let data = JSON.stringify(ad);
    return this.http.post<any>(this.apiUrl+"/playlist", ad, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  // saveAd(id, data) {
  //   data = JSON.stringify(data);

  //   const { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }} = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //     }),
  //     body: data,
  //     responseType: "text" as "json"
  //   };

  //   return this.http.put<any>("https://coffeelabsignage.herokuapp.com/api/display/"+id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  // }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }


}
