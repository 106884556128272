import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': 'OI94mCGG9zuGaHJYigHQTQRWMgwknfJCZy-NgA-WJvPkdVQHp5mS4YinY_vTFf0lW'
  })
};



@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  apiUrl: string = environment.general_url;
  
  constructor(
    private http: HttpClient
  ) { }

  getCampaignsList(): Observable<any> {

    return this.http.get<any>(this.apiUrl+"/campaigns",  { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }})
    .pipe(
      catchError(this.handleError)
    );
  }

  getScreenGroups(): Observable<any> {

    return this.http.get<any>(this.apiUrl+"/screengroups",  { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  getCampaingById(id) {
    return this.http.get<any>(this.apiUrl+"/campaigns/"+ id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  putCampaign(id, data): Observable<any> {

    return this.http.put<any>(this.apiUrl+"/campaigns/"+id, data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }



  publishCampaign(id, t): Observable<any> {

    return this.http.put<any>(this.apiUrl+"/campaigns/?campaign="+id+"&lastPublish="+t,'' ,  { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }


  newCampaign(data: any): Observable<any> {

    return this.http.post<any>(this.apiUrl+"/campaigns", data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  deleteCampaing(id) {
    return this.http.delete<any>(this.apiUrl+"/campaigns/"+ id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }




  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }


}
