import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
  MatToolbarModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatSidenavModule,
  MatListModule, MatExpansionModule, MatSelectModule, MatButtonToggleModule, MatPaginatorModule,
  MatSortModule, MatDividerModule, MatGridListModule, MatProgressBarModule, MatSnackBarModule,
  MatTreeModule, MatTabsModule, MatSlideToggleModule, MatAutocompleteModule, MatCheckboxModule
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';

import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSnackBarModule,
    DragDropModule,
    MatTreeModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSnackBarModule,
    DragDropModule,
    MatTreeModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule
  ],
  entryComponents: [
  ]
})

export class MaterialModule { }