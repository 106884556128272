import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AdService } from "src/app/services/ad/ad.service";
import { AdComponent } from "./../../components/ads/ad/ad.component";
import { AdsList } from "src/app/models/ad.model";
import { formatDate } from "@angular/common";

import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { FormControl } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  faFolderOpen,
  faFolder,
  faCloudUploadAlt,
  faTrashAlt,
  faFolderPlus,
  faSearch,
  faChevronCircleRight

} from "@fortawesome/free-solid-svg-icons";
import { CloudinaryService } from "../../services/cloudinary/cloudinary.service";

@Component({
  selector: "app-ads",
  templateUrl: "./ads.component.html",
  styleUrls: ["./ads.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class AdsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  faFolderOpen = faFolderOpen;
  faFolder = faFolder;
  faCloudUploadAlt = faCloudUploadAlt;
  faTrashAlt = faTrashAlt;
  faFolderPlus = faFolderPlus;
  faSearch = faSearch;
  faChevronCircleRight = faChevronCircleRight;
  ads: any;
  data: any;
  num_of_ads: number;
  showLoading = true;
  pageSizeOptions: number[] = [];
  columnsToDisplay: string[] = ["createDate", "name"];
  dataSource: any;
  checked = false;
  disabled = false;
  current_folder: string = "";
  currentTab: number = 0;
  oldImagesFolderIndex: number = -1;
  oldVideosFolderIndex: number = -1;
  public videosFolderArr: any[];
  public imagesFolderArr: any[];
  public images: any;
  private videos: any;
  order: string = "image.created_at";
  reverse: boolean = false;
  num_of_images: Number = 0;
  num_of_videos: Number = 0;

  filter = "";

  editMode: boolean = false;
  name = new FormControl("");
  public ad: any;
  public ad_id: string;
  public list: any[];
  bussinessID: string;


  constructor(
    private cloudinaryService: CloudinaryService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private adService: AdService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setOrder("created_at");

    this.bussinessID = sessionStorage.getItem("businessId");
    this.setOrder("created_at");
    this.current_folder = this.bussinessID + "/images";


    this.getImages();

    this.imagesFolderArr = [];

    this.cloudinaryService
      .getSubFolders(this.bussinessID + "/images")
      .subscribe((res55) => {
        this.imagesFolderArr = res55.folders;

        // console.log("All folders:", res55.folders);
      });

    this.cloudinaryService.getSubFolders(this.bussinessID + "/videos").subscribe(
      (res) => {
        this.videosFolderArr = res.folders;

        // console.log("All folders:", res.folders);
      },
      (error) => {
        //Error callback
        // console.error("error caught in component >>>>>>>>> ", error);
        // this.errorMessage = error;
        //this.loading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );

    this.filter = "";

    this.adService.getDisplayList().subscribe(
      (res) => {
        this.ads = res;
        this.num_of_ads = this.ads.length;
        // console.log("this.ads : ", res);
        // create the users table data source
        // 7 is option for all users
        this.createDataSource(7);
      },
      (error) => {
        //Error callback
        // console.error("error caught in component >>>>>>>>> ", error);
        // this.errorMessage = error;
        // this.loading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
  }

  createDataSource(level) {
    let adsList: AdsList["result"] = [];

    adsList = this.ads;

    const ELEMENT_DATA: AdsList["result"] = adsList;
    this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    //this.orderData('adId', 'desc')

    if (adsList.length > 24) this.pageSizeOptions = [12, 24, adsList.length];

    this.showLoading = false;
  }

  orderData(id: string, start?: "asc" | "desc") {
    const matSort = this.dataSource.sort;
    const disableClear = false;

    matSort.sort({ id: null, start, disableClear });
    matSort.sort({ id, start, disableClear });

    this.dataSource.sort = matSort;
  }

  editAd(ad) {
    // console.log("Edit AD >>>> ", ad);

    this.name.setValue(ad.name);
    this.ad = ad;

    // console.log("this.ad", this.ad);
    this.ad_id = this.ad._id;

    delete this.ad._id;
    //delete this.ad.__v;
    // console.log("Edit AD without __v >>>> ", ad);
    this.editMode = true;
    this.list = ad.list;
    // console.log(this.list);
  }

  newAd() {
    let data = {
      name: "New playlist",
      createDate: formatDate(new Date(), "dd/MM/yyyy", "en"),
      list: [],
    };

    this.adService.addPlaylist(data).subscribe((res) => {
      // console.log("ADD playlist res : ", res);
      this.adService.getDisplayList().subscribe((res) => {
        this.ads = res;
        this.num_of_ads = this.ads.length;
        // console.log("this.ads : ", this.ads);
        // create the users table data source
        // 7 is option for all users
        this.createDataSource(7);
      });
    });
  }

  closeEdit() {
    this.editMode = false;

    this.adService.getDisplayList().subscribe((res) => {
      this.ads = res;
      this.num_of_ads = this.ads.length;
      // console.log("this.ads : ", this.ads);
      // create the users table data source
      // 7 is option for all users
      this.createDataSource(7);
    });
  }

  saveEdit(ad) {
    //console.log(ad._id);

    // var result = ad.list.map(function(obj) {
    //   return {
    //     name: obj.name,
    //     type: obj.type,
    //     format: obj.format,
    //     duration: obj.duration,
    //     url: obj.url
    //   };
    // });

    // console.log("result: ", result);

    // let putAd = {
    //   name: ad.name,
    //   createDate: ad.createDate,
    //   list: result
    // };

    // console.log("For save ID => ", this.ad_id);
    // console.log("For save => ", this.ad);

    this.adService.putDisplay(this.ad_id, this.ad).subscribe((res) => {
      // console.log("PUT AD--------> ", res);

      this.adService.getDisplayList().subscribe((res) => {
        this.ads = res;
        this.num_of_ads = this.ads.length;
        // console.log("this.ads : ", this.ads);
        // create the users table data source
        // 7 is option for all users
        this.createDataSource(7);
      });
    });
    this.editMode = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
  }

  onTabClick(e) {
    switch (e.index) {
      case 0: {
        //console.log(e.index);

        //statements;
        this.current_folder = this.bussinessID + "/images";
        this.getImages();
        this.currentTab = 0;
        if (this.oldImagesFolderIndex > -1) {
          this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
            .imagesFolderArr[this.oldImagesFolderIndex].open;
        }
        this.oldImagesFolderIndex = -1;
        break;
      }
      case 1: {
        //console.log(e.index);

        this.current_folder = this.bussinessID + "/videos";
        this.getVideos();
        this.currentTab = 1;
        if (this.oldVideosFolderIndex > -1) {
          this.videosFolderArr[this.oldVideosFolderIndex].open = !this
            .videosFolderArr[this.oldVideosFolderIndex].open;
        }
        this.oldVideosFolderIndex = -1;
        break;
      }
      default: {
        //statements;
        break;
      }
    }

    // console.log("this.current_folder", this.current_folder);
  }

  getImages() {
    this.cloudinaryService
      .getAllImages(this.current_folder)
      .subscribe((res) => {
        // console.log("All images:", res);
        this.images = res.resources;
        this.num_of_images = this.images.length;
      });
  }

  getVideos() {
    this.cloudinaryService
      .getAllVideos(this.current_folder)
      .subscribe((res) => {
        // console.log("All videos:", res);
        this.videos = res.resources;
        this.num_of_videos = this.videos.length;
      });
  }

  togglImagesFolder(folderIndex) {
    if (folderIndex === -1) {
      this.current_folder = this.bussinessID + "/images";
      this.getImages();
      this.currentTab = 0;

      if (this.oldImagesFolderIndex > -1) {
        this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
          .imagesFolderArr[this.oldImagesFolderIndex].open;
      }
      this.oldImagesFolderIndex = -1;
      return;
    }

    if (this.oldImagesFolderIndex > -1) {
      this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
        .imagesFolderArr[this.oldImagesFolderIndex].open;
      this.imagesFolderArr[folderIndex].open = !this.imagesFolderArr[
        folderIndex
      ].open;
      this.oldImagesFolderIndex = folderIndex;
    } else {
      this.imagesFolderArr[folderIndex].open = !this.imagesFolderArr[
        folderIndex
      ].open;
      this.oldImagesFolderIndex = folderIndex;
    }

    if (this.imagesFolderArr[folderIndex].open) {
      this.current_folder = this.imagesFolderArr[folderIndex].path;
      this.getImages();
    }

    //console.log(this.imagesFolderArr);
  }

  togglVideosFolder(folderIndex) {
    if (folderIndex === -1) {
      this.current_folder = this.bussinessID + "/videos";
      this.getVideos();
      this.currentTab = 1;
      if (this.oldVideosFolderIndex > -1) {
        this.videosFolderArr[this.oldVideosFolderIndex].open = !this
          .videosFolderArr[this.oldVideosFolderIndex].open;
      }
      this.oldVideosFolderIndex = -1;
      return;
    }
    if (this.oldVideosFolderIndex > -1) {
      this.videosFolderArr[this.oldVideosFolderIndex].open = !this
        .videosFolderArr[this.oldVideosFolderIndex].open;
      this.videosFolderArr[folderIndex].open = !this.videosFolderArr[
        folderIndex
      ].open;
      this.oldVideosFolderIndex = folderIndex;
    } else {
      this.videosFolderArr[folderIndex].open = !this.videosFolderArr[
        folderIndex
      ].open;
      this.oldVideosFolderIndex = folderIndex;
    }

    if (this.videosFolderArr[folderIndex].open) {
      //console.log(this.videosFolderArr[folderIndex].path);

      this.current_folder = this.videosFolderArr[folderIndex].path;
      this.getVideos();
    }
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  addImage(image) {
    //  console.log(
    //   image.public_id.substring(image.public_id.lastIndexOf("/") + 1)
    // );
    let tmpImage = {
      duration: 10,
      format: "jpg",
      name: image.public_id.substring(image.public_id.lastIndexOf("/") + 1),
      type: "image",
      url: image.secure_url,
    };
    this.ad.list.push(tmpImage);

    // console.log("Image : ", image);
    // console.log("This.ad : ", this.ad);
  }

  addVideo(video) {
    // console.log("video >>>>> ", video);
    // console.log(
      // video.public_id.substring(video.public_id.lastIndexOf("/") + 1)
    // );
    let tmpVideo = {
      duration: 10,
      format: video.format,
      name: video.public_id.substring(video.public_id.lastIndexOf("/") + 1),
      type: "video",
      url: video.secure_url,
    };
    this.ad.list.push(tmpVideo);

    // console.log("Video : ", video);
    // console.log("This.ad : ", this.ad);
  }

  changeDuration(value: number, i): void {
    // console.log(i, value);
    this.ad.list[i].duration = value;
  }

  changeForever(value, i): void {
    // console.log(i, value.checked);
    this.ad.list[i].forEver = value.checked;

    // console.log(this.ad.list[i]);
  }

  deleteFromList(i) {
    this.ad.list.splice(i, 1);
    this.adService.putDisplay(this.ad_id, this.ad).subscribe((res) => {
      // console.log("PUT AD--------> ", res);
    });
  }

  deleteList(id) {
    // console.log("Delete ID: ", id);

    this.openDialog({ action: "deletePlaylist", id: id, res: null });
  }

  openDialog(data): void {
    let w = "50%";
    if (data.action == "deletePlaylist") {
      w = "unset";
    }
    const dialogRef = this.dialog.open(AdComponent, {
      width: w,
      data: data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);

      if (result.res == "ok") {
        this.num_of_ads  = 0;
        this.adService.deletePlaylist(data.id).subscribe(res1 => {
          // console.log("Delete Playlist--------> ", res1);
          this.ads = [];
          this.adService.getDisplayList().subscribe(res => {
            this.ads = res;
            this.num_of_ads = this.ads.length;
            // console.log("this.ads : ", this.ads);
            this.createDataSource(7);
          },
          (error) => {
            // console.error('error caught in component >>>>>>>>> ', error)
          });
        });
      }
    });
  }

  addYoutube(){


    const dialogRef = this.dialog.open(AdComponent, {
      width: "400px",
      data: { action: "addYoutube",  res: null },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('result', result);

      let tmpImage = {
        duration: 10,
        format: "video",
        name: 'smartSignage Youtube',
        type: "youtube",
        url: result.youtubeUrl
      };
      this.ad.list.push(tmpImage);

    });

       
  }
  addWeb(){


    const dialogRef = this.dialog.open(AdComponent, {
      width: "400px",
      data: { action: "addWeb",  res: null },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('result', result);

      let tmpImage = {
        duration: 10,
        format: "web",
        name: result.name,
        type: "web",
        url: result.webUrl,
        forever: false
      };
      this.ad.list.push(tmpImage);
      // console.log('List : ',this.ad.list);
    });

       
  }


  addVimeo(){
    const dialogRef = this.dialog.open(AdComponent, {
      width: "400px",
      data: { action: "addVimeo",  res: null },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('result', result);

      let tmpImage = {
        duration: 10,
        format: "video",
        name: 'smartSignage Vimeo',
        type: "vimeo",
        url: result.vimeoUrl
      };
      this.ad.list.push(tmpImage);

    });

       
  }

}
