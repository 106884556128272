import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MustMatch } from './must-match/must-match.validator';
import { TranslateService } from '@ngx-translate/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  defaultLang: string;
  hide = true;
  hide2 = true;

  registerForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required]
  },
    {
      validator: MustMatch('password', 'confirmPassword')
    })

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private signup: SignupService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(this.defaultLang);
  }

  onSubmit() {
    this.signup.signUpWithEmail(
      this.registerForm.get('email').value,
      this.registerForm.get('password').value).subscribe(res => {
        // console.log(res);

        let message = this.translate.instant("REGISTER.SUCCESS");
        let title = this.translate.instant("TITLE.SUCCESS");

        this.toastr.success(message, title);

        this.router.navigate(["login"]);
      },
        err => {
          // console.log(err.error);

          if (err.error == 'User already registered') {
            let message = this.translate.instant("REGISTER.ERROR");
            let title = this.translate.instant("TITLE.ERROR");

            this.toastr.error(message, title);
          }
        })
  }

  onClear() {
    this.registerForm.reset();
  }

}
