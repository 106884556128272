import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import {
  MAT_DIALOG_DATA
} from "@angular/material/dialog";


@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogBodyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit() {
  // console.log(this.data);

}

onNoClick(): void {
  this.dialogRef.close(this.data);
}

deleteImage() {
  this.data.res = 'ok';
  this.dialogRef.close(this.data);
}

deleteVideo() {
  this.data.res = 'ok';
  this.dialogRef.close(this.data);
}

}
