import { Component, OnInit } from "@angular/core";
import { ScreenService } from "src/app/services/screen/screen.service";
import { AdService } from "src/app/services/ad/ad.service";
import { CampaignService } from "src/app/services/campaign/campaign.service";
import { CloudinaryService } from "../../services/cloudinary/cloudinary.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  num_of_playlists: number = 0;
  num_of_campaigns: number = 0;
  num_of_screens: number = 0;
  num_of_screengroups: number = 0;
  num_of_images: number = 0;
  num_of_videos: number = 0;

  gaugeType = "arch";
  playlistsLabel = "";
  campaignsLabel = "";
  screensLabel = "";
  screengroupsLabel = "";
  videosLabel = "Videos";
  imagesLabel = "Images";
  gaugeAppendText = "";
  gThick = 6;
  screens: any;
  screengroups: any;
  playlists: any;
  campaigns: any;
  images: any;
  videos: any;
  thresholdConfig = {
    "0": { color: "orange" },
    "40": { color: "orange" },
    "75.5": { color: "red" },
  };
  current_folder: string = "images";
  bussinessID: string;



  constructor(
    private screenService: ScreenService,
    private adService: AdService,
    private campaignService: CampaignService,
    private cloudinaryService: CloudinaryService,
    private translate: TranslateService
  ) {}

  ngOnInit() {

    // console.error("Home Token >>>> ", sessionStorage.getItem('token'));
    this.bussinessID = sessionStorage.getItem("businessId");
    this.current_folder = this.bussinessID + "/images";
    this.screenService.getScreensList().subscribe(
      (res) => {
        // console.log("res >>>>>>>>>>>>>>>>>>> ", res);
        this.screens = res;
        this.num_of_screens = this.screens.length;
      }
    );

    this.screenService.getScreenGroupsList().subscribe(
      (res) => {
        this.screengroups = res;
        this.num_of_screengroups = this.screengroups.length;
      }
    );

    this.adService.getDisplayList().subscribe(
      (res) => {
        this.playlists = res;
        this.num_of_playlists = this.playlists.length;
      }
    );

    this.campaignService.getCampaignsList().subscribe(
      (res) => {
          this.campaigns = res;
          this.num_of_campaigns = this.campaigns.length;
      }
    );
    this.current_folder = this.bussinessID + "/images";
    this.cloudinaryService.getAllImages(this.current_folder).subscribe((res) => {
      // console.log("All images:", res);
      this.images = res.resources;
      // console.log("this.images:", this.images);

      this.num_of_images = this.images.length;
    });

    this.current_folder = this.bussinessID + "/videos";
    this.cloudinaryService.getAllVideos(this.current_folder).subscribe((res) => {
      // console.log("All images:", res);
      this.videos = res.resources;
      this.num_of_videos = this.videos.length;
    });
  }
}
