import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './core/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule,  } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ToastrModule } from 'ngx-toastr';
import { IMqttMessage, MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { FileUploadModule } from "ng2-file-upload";

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';

import {CloudinaryModule, CloudinaryConfiguration, provideCloudinary} from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import cloudinaryConfiguration from './config';
import {PhotoAlbum} from './services/photo-album.service';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AssetsComponent } from './components/assets/assets.component';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { ScreensComponent } from './components/screens/screens.component';
import { AdsComponent } from './components/ads/ads.component';
import { ContentComponent } from './components/content/content.component';
import { OrderModule } from 'ngx-order-pipe';
import { ScreenComponent } from './components/screens/screen/screen.component';
import { AdComponent } from './components/ads/ad/ad.component';
import { CampaignComponent } from './components/campaigns/campaign/campaign.component';
import { DialogBodyComponent } from './components/content/dialog-body/dialog-body.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxNumberSpinnerModule } from 'ngx-number-spinner';
import { MAT_DATE_LOCALE } from '@angular/material';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxGaugeModule } from 'ngx-gauge';
import { PreviewComponent } from './components/campaigns/preview/preview.component';
import { PlyrModule } from 'ngx-plyr';
import { RegisterComponent } from './components/register/register.component';
import { InstantComponent } from './components/instant/instant.component';
import { ColorPickerModule } from 'ngx-color-picker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const cloudinaryLib = {
  Cloudinary: Cloudinary
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    PageNotFoundComponent,
    NavbarComponent,
    HomeComponent,
    AssetsComponent,
    CampaignsComponent,
    ScreensComponent,
    AdsComponent,
    ContentComponent,
    ScreenComponent,
    AdComponent,
    CampaignComponent,
    DialogBodyComponent,
    PreviewComponent,
    RegisterComponent,
    InstantComponent
  ],
  imports: [
    NgxMaterialTimepickerModule,
    NgbModule,
    BrowserModule,
    FormsModule,
    OrderModule,
    NgxGaugeModule,
    ColorPickerModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PlyrModule,
    MaterialModule,
    FileUploadModule,
    LayoutModule,
    LightboxModule,
    NgxNumberSpinnerModule,
    CloudinaryModule.forRoot(cloudinaryLib, {
      cloud_name: 'dcrsbikfx',
      upload_preset: 'ktlmist3'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    MqttModule.forRoot(environment.MQTTCONFIG),
    ToastrModule.forRoot({
      timeOut: 3000
    }),
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
    }),
    HttpClientModule
  ],
  providers: [PhotoAlbum, {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_DATE_LOCALE, useValue: 'el-GR'}
],
  bootstrap: [AppComponent],
  entryComponents: [CampaignComponent, ScreenComponent, DialogBodyComponent, AdComponent, PreviewComponent]
})
export class AppModule { }
