import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ScreenList } from 'src/app/models/screen.model';
import { Screen } from 'src/app/models/screen.model';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';



const httpOptions1 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'  
  }),  
  responseType: 'text' as 'json'
};


@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  apiUrl: string = environment.general_url;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient
  ) { }

  getScreenGroupsList(): Observable<any> {

    return this.http.get<any>(this.apiUrl+"/screengroups", { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }} )
    .pipe(
      catchError(this.handleError)
    );
  }


  addScreenGroup(data: any): Observable<any> {

    return this.http.post<any>(this.apiUrl+"/screengroups", data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }
  
  deleteScreenGroup(id:string): Observable<any> {

    return this.http.delete<any>(this.apiUrl+"/screengroups/"+id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

  putScreenGroup(id, data: any): Observable<Screen> {

    return this.http.put<Screen>(this.apiUrl+"/screengroups/"+id, data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }
  

  getScreensList(): Observable<ScreenList> {

    return this.http.get<ScreenList>(this.apiUrl+"/screens",  { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }})
    .pipe(
      catchError(this.handleError)
    );
  }

  getScreenById(id): Observable<ScreenList> {

    return this.http.get<ScreenList>(this.apiUrl+"/screens/"+id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }


  deleteScreen(id:string): Observable<ScreenList> {
    return this.http.delete<ScreenList>(this.apiUrl+"/screens/"+id, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
  }

addScreen(data: any): Observable<Screen> {

  return this.http.post<Screen>(this.apiUrl+"/screens", data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
}


putScreen(id, data: any): Observable<Screen> {

  return this.http.put<Screen>(this.apiUrl+"/screens/"+id, data, { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }});
}


signInWithEmailAndPassword(email: string, password: string): Observable<any> {
  let data = {"email": email, "password": password};

  return this.http.post<any>(this.apiUrl+"/auth", data, httpOptions1);
}


private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // Return an observable with a user-facing error message.
  return throwError(
    error.status);
}

}
