import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ScreenService } from "src/app/services/screen/screen.service";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material";
import { ScreenList } from "src/app/models/screen.model";
import { ScreenGroupsList } from "src/app/models/screengroups.model";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ScreenComponent } from "./../../components/screens/screen/screen.component";

@Component({
  selector: "app-screens",
  templateUrl: "./screens.component.html",
  styleUrls: ["./screens.component.css"],
})
export class ScreensComponent implements OnInit {
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  screens: any;
  screengroups: any;
  data: any;
  num_of_screens: number;
  num_of_screengroups: number = 0;
  showLoading = true;
  screenPageSizeOptions: number[] = [];
  groupPageSizeOptions: number[] = [];
  displayedColumns: string[] = [
    "screenId",
    "name",
    "address",
    "phone",
    "email",
    "deviceId",
    "enabled",
    "actions",
  ];
  screengroupsColumns: string[] = ["name", "actions"];
  dataSource: any;
  dataSource1: any;
  checked = false;
  disabled = false;
  filter = "";
  defaultOption: number;
  activeTab: string = "screens";

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private screenService: ScreenService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filter = "";

    this.screenService.getScreenGroupsList().subscribe(
      (res) => {
        this.screengroups = res;
        this.num_of_screengroups = this.screengroups.length;
        // console.log(this.screengroups);
        // create the users table data source
        // 7 is option for all users
        this.createScreenGroupsDataSource();
      },
      (error) => {
        //Error callback
        // console.error("error caught in component >>>>>>>>> ", error);
        // this.errorMessage = error;
        this.showLoading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
    this.screenService.getScreensList().subscribe(
      (res) => {
        this.screens = res;
        this.num_of_screens = this.screens.length;
        // console.log(this.screens);
        // create the users table data source
        // 7 is option for all users
        this.createDataSource(7);
      },
      (error) => {
        //Error callback
        // console.error("error caught in component >>>>>>>>> ", error);
        // this.errorMessage = error;
        this.showLoading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
  }

  createScreenGroupsDataSource() {
    let screengroupsList: ScreenGroupsList["result"] = [];

    screengroupsList = this.screengroups;

    const ELEMENT_DATA1: ScreenGroupsList["result"] = screengroupsList;
    this.dataSource1 = new MatTableDataSource<any>(ELEMENT_DATA1);
    // this.dataSource1.paginator = this.paginator;
    // this.dataSource1.sort = this.sort;

    this.dataSource1.paginator = this.paginator.toArray()[1];
    this.dataSource1.sort = this.sort.toArray()[1];

    //this.orderData('screenId', 'desc')

    if (screengroupsList.length > 24)
      this.groupPageSizeOptions = [12, 24, screengroupsList.length];

    this.showLoading = false;
  }

  createDataSource(level) {
    // store the level to use it when the dataSource
    // will be re-generated
    this.defaultOption = level;

    let screenList: ScreenList["result"] = [];

    screenList = this.screens;

    const ELEMENT_DATA: ScreenList["result"] = screenList;
    this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort.toArray()[0];

    this.orderData("screenId", "desc");

    if (screenList.length > 24)
      this.screenPageSizeOptions = [12, 24, screenList.length];

    this.showLoading = false;
  }

  orderData(id: string, start?: "asc" | "desc") {
    const matSort = this.dataSource.sort;
    const disableClear = false;

    matSort.sort({ id: null, start, disableClear });
    matSort.sort({ id, start, disableClear });

    this.dataSource.sort = matSort;
  }

  onDeleteScreen(id) {
    // console.log(id);

    this.onOpenDialog("delete", id);
  }

  onDeleteScreenGroup(id) {
    // console.log(id);

    this.openDeleteGroupDialog(id);
  }

  openDeleteGroupDialog(id): void {
    const dialogRef = this.dialog.open(ScreenComponent, {
      width: "250px",
      data: { action: "deleteGroup", id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("The deleteGroup dialog was closed :", result);

      if (result.res == "ok") {
        this.screenService.deleteScreenGroup(result.id).subscribe((res) => {
          // console.log(res);
          this.screenService.getScreenGroupsList().subscribe(
            (res1) => {
              this.screengroups = res1;
              this.num_of_screengroups = this.screengroups.length;
              // console.log("this.screengroups >>>>>>>> ", this.screengroups);
              this.createScreenGroupsDataSource();
            },
            (error) => {
              //Error callback
              // console.error("error caught in component >>>>>>>>> ", error);
              // this.errorMessage = error;
              // this.loading = false;
              if (error == 404) {
                this.screengroups = [];
                this.num_of_screengroups = this.screengroups.length;
                // console.log("this.screengroups >>>>>>>> ", this.screengroups);
                this.createScreenGroupsDataSource();
              }
              //throw error;   //You can also throw the error to a global error handler
            }
          );
        });
      }

      //this.animal = result;

      // this.screenService.deleteScreenGroup(id).subscribe(res => {
      //   console.log(res);

      //   if (!res.error) {
      //     let message = this.translate.instant("DELETE.SCREEN");
      //     let title = this.translate.instant("TITLE.SUCCESS");
      //     this.toastr.success(message, title);

      //     this.screenService.getScreenGroupsList().subscribe(res => {
      //       this.screengroups = res;
      //       this.num_of_screengroups = this.screengroups.length;
      //       console.log(this.screengroups);
      //       this.createScreenGroupsDataSource();
      //     });

      //   } else {
      //     let message = this.translate.instant("USER.PROBLEM");
      //     let title = this.translate.instant("TITLE.ERROR");
      //     this.toastr.error(message, title);
      //   }
      // });
    });
  }

  addScreen() {
    this.onOpenDialog("new", null);
  }

  addScreenGroup() {
    this.onOpenDialog("newGroup", null);
  }

  editScreen(row) {
    // console.log("********************     edit row >>>> ", row);
    this.onOpenDialog("edit", row);
  }

  editScreenGroup(row) {
    // console.log(row);
    this.onOpenDialog("editGroup", row);
  }

  onOpenDialog(action, row) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    if (action == "newGroup") dialogConfig.width = "50%";
    if (action == "delete") dialogConfig.width = "unset";

    dialogConfig.data = { action: action, row: row };

    const dialogRef = this.dialog.open(ScreenComponent, dialogConfig);
    // create again the data source after the data update

    dialogRef.afterClosed().subscribe((data) => {
      // console.log("data >>>>>>>>>>>>> ", data);
      this.showLoading = false;

      if (data && data.action == "delete") {
        this.showLoading = true;

        this.screenService.deleteScreen(data.row).subscribe(
          (res) => {
            let message = this.translate.instant("DELETE.SCREEN");
            let title = this.translate.instant("TITLE.SUCCESS");
            this.toastr.success(message, title);

            this.screenService.getScreensList().subscribe((res) => {
              // console.log("getScreenList after Delete");
              this.screens = res;
              this.num_of_screens = this.screens.length;

              this.createDataSource(this.defaultOption);
            },
            (error) => {
              this.screens = [];
              this.num_of_screens = this.screens.length;
    
              this.createDataSource(this.defaultOption);
              this.showLoading = false;
            });

        });
      }
      // console.log("Edo <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
      this.screenService.getScreensList().subscribe(
        (res1) => {
          this.screens = res1;
          this.num_of_screens = this.screens.length;

          // default option is set when the user selected
          // an option from the drop down list
          this.createDataSource(this.defaultOption);
        },
        (error) => {
          //Error callback
          // console.error("error caught in component >>>>>>>>> ", error);
          // this.errorMessage = error;
          // this.loading = false;
          //throw error;   //You can also throw the error to a global error handler
        }
      );

      this.screenService.getScreenGroupsList().subscribe(
        (res) => {
          this.screengroups = res;
          this.num_of_screengroups = this.screengroups.length;
          // console.log(this.screengroups);
          // create the users table data source
          // 7 is option for all users
          this.createScreenGroupsDataSource();
        },
        (error) => {
          //Error callback
          // console.error("error caught in component >>>>>>>>> ", error);
          // this.errorMessage = error;
          // this.loading = false;
          //throw error;   //You can also throw the error to a global error handler
        }
      );
    });
  }

  onSlideChange(e, row, enabled) {
    // console.log(e, row._id, enabled);
    this.data = {
      name: row.name,
      address: row.address,
      phone: row.phone,
      email: row.email,
      enabled: e,
      deviceId: row.deviceId,
      deviceSerial: row.deviceSerial,
      registered: row.registered,
      registerdate: row.registerdate,
      orientation: row.orientation,
      screenId: row.screenId,
      pin: row.pin,
    };

    this.screenService.putScreen(row._id, this.data).subscribe((res) => {
      // console.log("PUT : ", res);

      this.screenService.getScreensList().subscribe((res1) => {
        this.screens = res1;
        this.num_of_screens = this.screens.length;

        // default option is set when the user selected
        // an option from the drop down list
        this.createDataSource(this.defaultOption);
      });
    });
  }

  onTabClick(e) {
    // console.log(e.index);
    if (e.index == 0) this.activeTab = "screens";
    else this.activeTab = "screenGroups";
  }
}
