import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  defaultLang: string;
  hide     = true;
  
  loginForm = this.formBuilder.group({
    email           : ['', [Validators.required, Validators.email]],
    password        : ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private translate: TranslateService,
    private langService: LanguageService,
    private dialog: MatDialog) {}

  ngOnInit() {
    // console.log("1 localStorage lang: " + localStorage.defaultLang)
    // console.log("1 broswer lang: " + this.translate.getBrowserLang())
    // if (localStorage.getItem('defaultLang') === null) ------------- old code
     if (!this.langService.getLanguage()) {
      this.defaultLang = this.translate.getBrowserLang();
      // console.log("2 broswer lang: " + this.translate.getBrowserLang())

      if (!this.defaultLang.match("en|el")) {
        // localStorage.setItem('defaultLang', 'en');  -------------- old code
        this.langService.setLanguage("el");
        // console.log("2 localStorage lang: " + localStorage.defaultLang)
        this.defaultLang = "en";
      }
    }
    else {
      // this.defaultLang = localStorage.getItem('defaultLang'); ------------ old code
      this.defaultLang = this.langService.getLanguage();
      // console.log("3 localStorage lang: " + localStorage.defaultLang)
    }
    // localStorage.setItem('defaultLang', this.defaultLang); ------------- old code
    this.langService.setLanguage(this.defaultLang);
    // console.log("4 localStorage lang: " + localStorage.defaultLang)
    
    this.translate.use(this.defaultLang);
  }

  onSubmit() {
    this.loginService.login(this.loginForm.get('email').value, 
                            this.loginForm.get('password').value);
  }

  onClear() {
    this.loginForm.reset();
  }

  setLang(lang: string) {
    // localStorage.setItem('defaultLang', lang); ---------------------- old code
    this.langService.setLanguage(lang);

    this.translate.use(lang);
  }

  // resetPassword() {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  // }
}
