import { Injectable, RendererFactory2, Renderer2 } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, of, fromEvent } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';

//const widgetUrl = 'https://widget.cloudinary.com/v2.0/global/all.js';
const nodeUrl = "https://coffeelabsignage.herokuapp.com/api/content";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json; charset=utf-8",
    'x-auth-token': sessionStorage.getItem('token'),
    "Access-Control-Allow-Origin": "*"
  })
  // responseType: "text" as "json"
};

@Injectable({
  providedIn: "root"
})
export class CloudinaryService {
  private renderer: Renderer2;
  images = [];
  apiUrl: string = environment.general_url;
  
  constructor(private http: HttpClient, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

    // console.log("User id: ", sessionStorage.getItem("userId"));
  }

  // public createUploadWidget(data: any, callback: (error: any, result: any) => void): Observable<any> {
  //   return this.skriptExists(widgetUrl)
  //     ? of(cloudinary.createUploadWidget(data, callback))
  //     : fromEvent(this.addJsToElement(widgetUrl), 'load').pipe(
  //       map(e => cloudinary.createUploadWidget(data, callback))
  //     );
  // }

  // private skriptExists(src: string): boolean {
  //   return document.querySelector('script[src="' + src + '"]') ? true : false;
  // }

  // private addJsToElement(src: string): HTMLScriptElement {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = src;
  //   this.renderer.appendChild(document.body, script);
  //   return script;
  // }

  getAllImages(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.get<any>(this.apiUrl + "/content?folder=" + folder + "&rType=image",
    { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    );
  }

  getAllVideos(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.get<any>(this.apiUrl + "/content?folder=" + folder + "&rType=video",
    { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    );
  }

  getSubFolders(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.get<any>(this.apiUrl + "/content/folders?folder=" + folder,
    { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    );
  }

  deleteSubfolder(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.delete<any>(
      this.apiUrl + "/content/folders?folder=" + folder,
      { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    )
    .pipe(
      catchError(this.handleError)
    );;
  }

  addImagesSubfolder(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.post<any>(
      this.apiUrl + "/content/folders?folder=" + folder,'',
      { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    );
  }

  addVideosSubfolder(folder): Observable<any> {
    // console.log("folder = ", folder);
    return this.http.post<any>(
      this.apiUrl + "/content/folders?folder=" + folder,'',
      { 'headers': {'Content-Type': 'application/json' , 'x-auth-token':sessionStorage.getItem('token') }}
    );
  }

  deleteImage(data): Observable<any> {
    // console.log("data = ", JSON.stringify(data));
    data = JSON.stringify(data);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'x-auth-token': sessionStorage.getItem('token')
      }),
      body: data,
      responseType: "text" as "json"
    };

    return this.http.delete<any>(this.apiUrl + "/content/images", httpOptions);
  }

  
  deleteVideo(data): Observable<any> {
    // console.log("data = ", JSON.stringify(data));
    data = JSON.stringify(data);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'x-auth-token': sessionStorage.getItem('token')
      }),
      body: data,
      responseType: "text" as "json"
    };

    return this.http.delete<any>(this.apiUrl + "/content/videos", httpOptions);
  }

  

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      error.status
      );
  }


}
