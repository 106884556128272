// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAajC3JKsi4rRt6QJXXmaVzeu1rB4Cl9Ps",
    authDomain: "smartsignage-53e6c.firebaseapp.com",
    databaseURL: "https://smartsignage-53e6c.firebaseio.com",
    projectId: "smartsignage-53e6c",
    storageBucket: "smartsignage-53e6c.appspot.com",
    messagingSenderId: "493606990741",
    appId: "1:493606990741:web:08a34efe86f8cdee5b3c9c",
    measurementId: "G-MXJH2DJ84G"
  },
  google_maps: {
    apiKey: "AIzaSyBon4PXD6sA5y8_cIalZG9trOa95ypjCOM"
  },
  MQTTCONFIG: {
    broker: 'smartanalysis.ddns.net',
    hostname: 'smartanalysis.ddns.net',
    // broker: 'iot.eclipse.org',
    // hostname: 'iot.eclipse.org',
    protocol: 'ws',
    port: 9001,
    username: 'smartanalysis',
    password: 'q0ybct',
    path: '/',
    // newscreen_topic: 'smartsignage/newscreen',
    newscreen_topic: 'coffeelab/newscreen',
    topic_update_state: 'myPayments/geolocation',
    clientID: 'myPayments',
    // rejectUnauthorized: false
  },
  encryptionKey: {
    key: '12345678910123456789012345678901'
  },
  general_url: 'https://smartanasignage.herokuapp.com/api'
  // general_url: 'http://25.81.53.192:3000/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
