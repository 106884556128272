import { Component, OnInit, Inject, Input, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PlyrComponent } from "ngx-plyr";
import * as Plyr from "plyr";

import {
  trigger,
  transition,
  style,
  animate,
  animation,
  useAnimation,
} from "@angular/animations";

const player = new Plyr(document.getElementById("player"));

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.css"],
  animations: [
    trigger("carouselAnimation", [
      transition("* => void", [
        style({ opacity: 1 }),
        animate("400ms", style({ opacity: 0 })),
      ]),
      transition("void => *", [
        style({ opacity: 0 }),
        animate("400ms", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class PreviewComponent implements OnInit {
  youtubeSources = [
    {
      src: "",
      provider: "youtube",
    },
  ];

  vimeoSources = [
    {
      src: "",
      provider: "vimeo",
    },
  ];

  videoSources: Plyr.Source[] = [
    {
      src:
        "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4",
      type: "video/mp4",
      size: 576,
    },
    {
      src:
        "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4",
      type: "video/mp4",
      size: 720,
    },
    {
      src:
        "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4",
      type: "video/mp4",
      size: 1080,
    },
    {
      src:
        "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1440p.mp4",
      type: "video/mp4",
      size: 1440,
    },
  ];

  options: Plyr.Options = {
    autoplay: true,
    invertTime: false,
    disableContextMenu: true,
    settings: ["quality", "speed", "loop"],
    controls: [],
    captions: { active: true, update: true, language: "en" },
  };

  // @Input() slides;
  public currentIndex: number = 0;
  public previewList = [];
  public currentSlide = 0;

  timeLeft: number = 10;
  interval;
  @ViewChild(PlyrComponent, { static: true })
  plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  constructor(
    private dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    // conso.log("------> ", data.previewList);
    this.previewList = data.previewList;
  }

  ngOnInit() {
    
    this.preloadImages();
    this.currentSlide = 0;
    this.timeLeft = this.previewList[0].duration;

    if (this.previewList[0].type == "web") {
      // console.log("Web page OnInit",this.previewList[0].url);
      this.timeLeft = 99999;
    }

    this.startTimer();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  initPlayer(e) {
    // consol.log('initPlayer -----> ',e);
    this.player = e;
    this.player.toggleControls(false);

    if (this.previewList[this.currentSlide].type == "video") {
      this.player.source = {
        type: "video",
        title: "Example title",
        sources: [
          {
            src: this.previewList[this.currentSlide].url,
            type: "video/mp4",
            size: 720,
          },
          {
            src: this.previewList[this.currentSlide].url,
            type: "video/mp4",
            size: 1080,
          },
        ],
      };
    }

    if (this.previewList[this.currentSlide].type == "youtube") {
      this.player.source = {
        type: "video",
        title: "Youtube",
        sources: [
          {
            src: this.previewList[this.currentSlide].url,
            provider: "youtube",
          },
        ],
      };
    }

    if (this.previewList[this.currentSlide].type == "vimeo") {
      // consol.log('Type ----> ',this.previewList[this.currentSlide].type)
      // consol.log('Url ----> ',this.previewList[this.currentSlide].url)
      this.player.source = {
        type: "video",
        title: "Vimeo",
        sources: [
          {
            src: this.previewList[this.currentSlide].url,
            provider: "vimeo",
          },
        ],
      };
    }

    this.player.on("ready", () => {
      // consol.log('Player ready')
      this.player.play();
    });
    // var self = this;
    // this.timeLeft = 999;
    //   console.log('Τώρα .............................');
    // this.player.on('play', ()=>{
    //   console.log('Video this.player.duration : ', this.player.duration);
    //   console.log('Video this.player.currentTime : ', this.player.currentTime);
    //   this.timeLeft = Math.floor(this.player.duration) ;
    //   if(Math.floor(this.player.duration - this.player.currentTime) < 2) self.timeLeft = 0;
    //   console.log('Video time rmaining : ', self.timeLeft );
    // })
    // this.player.on('ended', ()=>{
    //   self.timeLeft = 0;
    // })
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        if (this.previewList[this.currentSlide].type == "image")
          this.timeLeft--;
      } else {
        // animate("300ms", style({ opacity: 0 }));
        this.currentSlide++;
        if (this.currentSlide > this.previewList.length - 1)
          this.currentSlide = 0;
        if (this.previewList[this.currentSlide].type == "image")
          this.timeLeft = this.previewList[this.currentSlide].duration;

        if (this.previewList[this.currentSlide].type == "video") {
          this.timeLeft = 99999;
        }
        if (this.previewList[this.currentSlide].type == "youtube") {
          this.timeLeft = 99999;
        }

        if (this.previewList[this.currentSlide].type == "web") {
          // console.log("Web page");
          this.timeLeft = 99999;
        }

        if (this.previewList[this.currentSlide].type == "vimeo") {
          this.timeLeft = 99999;
        }

        // console.log(
        //   this.previewList[this.currentSlide].type,
        //   this.previewList[this.currentSlide].duration
        // );
      }
      // console.log(this.timeLeft);
    }, 1000);
  }

  played(event: Plyr.PlyrEvent) {
    //console.log('played', event);
    //this.timeLeft = this.player.duration;
    this.timeLeft = Math.floor(this.player.duration - this.player.currentTime);
    if (this.timeLeft < 1) this.timeLeft = 0;

    // conso.log('this.player.duration : ',this.player.duration )
    // conso.log('this.timeLeft : ',this.timeLeft )
  }

  play(): void {
    this.plyr.player.play();
  }

  startShow() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // animate("300ms", style({ opacity: 0 }));
        this.currentSlide++;
        if (this.currentSlide > this.previewList.length - 1)
          this.currentSlide = 0;
        this.timeLeft = this.previewList[this.currentSlide].duration;
      }
    }, 1000);
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.previewList.length - 1 : previous;
    // conso.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.previewList.length ? 0 : next;
    // conso.log("next clicked, new current slide is: ", this.currentSlide);
  }

  preloadImages() {
    for (const slide of this.previewList) {
      new Image().src = slide.url;
    }
  }
}
