import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from "@angular/material/dialog";

//import { PhotoAlbum } from "../../services/photo-album.service";
import { CloudinaryService } from "../../services/cloudinary/cloudinary.service";
//import { Photo } from "../../models/photo";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Lightbox } from "ngx-lightbox";

import {
  faFolderOpen,
  faFolder,
  faCloudUploadAlt,
  faTrashAlt,
  faFolderPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { DialogBodyComponent } from "./dialog-body/dialog-body.component";

declare var cloudinary;

export interface DialogData {
  folderName: string;
}

export class FileNode {
  children: FileNode[];
  filename: string;
  type: any;
}

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
})
export class ContentComponent implements OnInit {
  faFolderOpen = faFolderOpen;
  faFolder = faFolder;
  faCloudUploadAlt = faCloudUploadAlt;
  faTrashAlt = faTrashAlt;
  faFolderPlus = faFolderPlus;
  faSearch = faSearch;
  folderName: string;
  animal: string;
  private _album: any[] = [];

  name = "widget user";
  //widget: any;
  //private photos: Observable<Photo[]>;
  public images: any;
  private videos: any;
  order: string = "image.created_at";
  reverse: boolean = false;
  num_of_images: Number = 0;
  num_of_videos: Number = 0;
  current_folder: string = "images";
  public videosFolderArr: any[];
  public imagesFolderArr: any[];
  currentTab: number = 0;
  oldImagesFolderIndex: number = -1;
  oldVideosFolderIndex: number = -1;
  bussinessID: string;

  constructor(
    private cloudinaryService: CloudinaryService,
    private http: HttpClient,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _lightbox: Lightbox
  ) {}

  ngOnInit(): void {
    // console.log("bussinessID ====== ", sessionStorage.getItem("businessId"));
    this.bussinessID = sessionStorage.getItem("businessId");
    this.setOrder("created_at");
    this.current_folder = this.bussinessID + "/images";

    // console.log("this.current_folder >>>>> ", this.current_folder);

    this.getImages();

    this.imagesFolderArr = [];

    this.cloudinaryService
      .getSubFolders(this.bussinessID + "/images")
      .subscribe((res55) => {
        this.imagesFolderArr = res55.folders;

        // console.log("All folders:", res55.folders);
      });

    this.cloudinaryService
      .getSubFolders(this.bussinessID + "/videos")
      .subscribe((res) => {
        this.videosFolderArr = res.folders;

        //console.log("All folders:", res.folders);
      });
  }

  onLoadImage(success) {
    //console.log("On load", success);
  }
  onErrorImage(err) {
    //console.log("On error!!", err);
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  toggle(event) {
    //console.log(event);
  }

  onTabClick(e) {
    switch (e.index) {
      case 0: {
        //console.log(e.index);

        //statements;
        this.current_folder = this.bussinessID + "/images";
        this.getImages();
        this.currentTab = 0;
        if (this.oldImagesFolderIndex > -1) {
          this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
            .imagesFolderArr[this.oldImagesFolderIndex].open;
        }
        this.oldImagesFolderIndex = -1;
        break;
      }
      case 1: {
        //console.log(e.index);

        this.current_folder = this.bussinessID + "/videos";
        this.getVideos();
        this.currentTab = 1;
        if (this.oldVideosFolderIndex > -1) {
          this.videosFolderArr[this.oldVideosFolderIndex].open = !this
            .videosFolderArr[this.oldVideosFolderIndex].open;
        }
        this.oldVideosFolderIndex = -1;
        break;
      }
      default: {
        //statements;
        break;
      }
    }

    //console.log("this.current_folder", this.current_folder);
  }

  getImages() {
    this.cloudinaryService
      .getAllImages(this.current_folder)
      .subscribe((res) => {
        //console.log("All images:", res);
        this.images = res.resources;
        this.num_of_images = this.images.length;
      });
  }

  getVideos() {
    this.cloudinaryService
      .getAllVideos(this.current_folder)
      .subscribe((res) => {
        //console.log("All videos:", res);
        this.videos = res.resources;
        this.num_of_videos = this.videos.length;
      });
  }

  deleteImagesSubfolder(path, i) {
    // console.log("current folder onDelete >>>>>", this.current_folder);
    //this.current_folder='coffeelab/images/123456';
    this.cloudinaryService.deleteSubfolder(this.current_folder).subscribe(
      (result) => {
        // console.log("Delete subfolder >>>>>>> result >>>>>>> :", result);
        this.deleteImagesArrSubfolder(i);

        this.current_folder = this.bussinessID + "/images";
        this.getImages();
      },
      (error) => {
        let message = "";
        // console.log(error);
        if (error == 400) {
          message = "ΔΙΑΓΡΑΦΗ ΑΔΥΝΑΤΗ! Ο φάκελος πρέπει να είναι άδειος";
          this.openSnackBar(message, "");
        } else {
          this.openSnackBar("Κωδικός λάθους : ", error);
        }
      }
    );
  }

  deleteImagesArrSubfolder(i) {
    this.imagesFolderArr.splice(i, 1);
    this.oldImagesFolderIndex = -1;
    //console.log("this.imagesFolderArr = ", this.imagesFolderArr);
  }

  deleteVideosSubfolder(path, i) {
    //this.current_folder='coffeelab/images/123456';
    this.cloudinaryService.deleteSubfolder(this.current_folder).subscribe(
      (result) => {
        //console.log("Delete subfolder >>>>>>> result >>>>>>> :", result);
        this.deleteVideosArrSubfolder(i);
        this.current_folder = this.bussinessID + "/videos";
        this.getVideos();
      },
      (error) => {
        let message = "";
        // console.log(error);
        if (error == 400) {
          message = "ΔΙΑΓΡΑΦΗ ΑΔΥΝΑΤΗ! Ο φάκελος πρέπει να είναι άδειος";
          this.openSnackBar(message, "");
        } else {
          this.openSnackBar("Κωδικός λάθους : ", error);
        }
      }
    );
  }

  deleteVideosArrSubfolder(i) {
    this.videosFolderArr.splice(i, 1);
    this.oldVideosFolderIndex = -1;
    //console.log("this.imagesFolderArr = ", this.imagesFolderArr);
  }

  // getSubfolders() {
  //   this.cloudinaryService
  //     .getSubFolders("coffeelab/images")
  //     .subscribe(res555 => {
  //      //console.log("216 - All folders - res555:", res555);
  //       this.ngOnInit();
  //       // this.imagesFolderArr = res555.folders;
  //     });
  // }

  togglImagesFolder(folderIndex) {
    if (folderIndex === -1) {
      this.current_folder = this.bussinessID + "/images";
      this.getImages();
      this.currentTab = 0;

      if (this.oldImagesFolderIndex > -1) {
        this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
          .imagesFolderArr[this.oldImagesFolderIndex].open;
      }
      this.oldImagesFolderIndex = -1;
      return;
    }

    if (this.oldImagesFolderIndex > -1) {
      this.imagesFolderArr[this.oldImagesFolderIndex].open = !this
        .imagesFolderArr[this.oldImagesFolderIndex].open;
      this.imagesFolderArr[folderIndex].open = !this.imagesFolderArr[
        folderIndex
      ].open;
      this.oldImagesFolderIndex = folderIndex;
    } else {
      this.imagesFolderArr[folderIndex].open = !this.imagesFolderArr[
        folderIndex
      ].open;
      this.oldImagesFolderIndex = folderIndex;
    }

    if (this.imagesFolderArr[folderIndex].open) {
      this.current_folder = this.imagesFolderArr[folderIndex].path;
      this.getImages();
    }

    // console.log(this.imagesFolderArr);
  }

  togglVideosFolder(folderIndex) {
    if (folderIndex === -1) {
      this.current_folder = this.bussinessID + "/videos";
      this.getVideos();
      this.currentTab = 1;
      if (this.oldVideosFolderIndex > -1) {
        this.videosFolderArr[this.oldVideosFolderIndex].open = !this
          .videosFolderArr[this.oldVideosFolderIndex].open;
      }
      this.oldVideosFolderIndex = -1;
      return;
    }
    if (this.oldVideosFolderIndex > -1) {
      this.videosFolderArr[this.oldVideosFolderIndex].open = !this
        .videosFolderArr[this.oldVideosFolderIndex].open;
      this.videosFolderArr[folderIndex].open = !this.videosFolderArr[
        folderIndex
      ].open;
      this.oldVideosFolderIndex = folderIndex;
    } else {
      this.videosFolderArr[folderIndex].open = !this.videosFolderArr[
        folderIndex
      ].open;
      this.oldVideosFolderIndex = folderIndex;
    }

    if (this.videosFolderArr[folderIndex].open) {
      //console.log(this.videosFolderArr[folderIndex].path);

      this.current_folder = this.videosFolderArr[folderIndex].path;
      this.getVideos();
    }
  }

  uploadButton() {
    this.open(this.current_folder);
  }

  uploadImage(p) {
    // console.log("path=", p);
    this.current_folder = p;

    this.open(p);
  }

  uploadVideo(p) {
    // console.log("path=", p);
    this.current_folder = p;

    this.open(p);
  }

  open(curFolder) {
    let allForm = [];

    if (this.currentTab == 0) allForm = ["png", "gif", "jpeg"];
    if (this.currentTab == 1) allForm = ["mp4", "avi"];
    // console.log("OPEN >>>>>>>>>> currentTab", this.currentTab);
    cloudinary.openUploadWidget(
      {
        cloud_name: "dgreyehwz",
        upload_preset: "ib53twft",
        // folder: this.bussinessID+'/'+curFolder,
        folder: curFolder,
        sources: ["local", "url"],
        client_allowed_formats: allForm,
      },
      (error, result) => {
        // console.log(error, result);
        if (result) {
          if (this.currentTab == 0) this.getImages();
          if (this.currentTab == 1) this.getVideos();
        }
      }
    );
  }

  addImagesSubfolder() {
    //console.log("addSubfolder");
    this.openDialog({ action: "addImagesFolder", folder: "" });
  }

  addVideosSubfolder() {
    //console.log("addSubfolder");
    this.openDialog({ action: "addVideosFolder", folder: "" });
  }

  openDialog(data): void {
    let w = "50%";
    if (data.action == "addImagesFolder" || data.action == "addVideosFolder" || data.action == "deleteImage" || data.action == "deleteVideo") {
      w = "unset";
    }
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      width: w,
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("The dialog was closed >>>", result);

      if (result) {
        if (result.action == "deleteImage") {
          // console.log(result);
          if (result.res == "ok") {
            // console.log(result.id);

            let data = { public_ids: [result.id] };

            this.cloudinaryService.deleteImage(data).subscribe((res87) => {
              // console.log("deleteImage res ====", res87);
              // console.log("data====", JSON.stringify(data));
              this.getImages();
            });
          }
        }

        if (result.action == "deleteVideo") {
          // console.log(">>>>>>>>>> ", result);
          if (result.res == "ok") {
            // console.log(result.id);

            let data = { public_ids: [result.id] };

            this.cloudinaryService.deleteVideo(data).subscribe((res87) => {
              // console.log("deleteImage res ====", res87);
              // console.log("data====", JSON.stringify(data));
              this.getVideos();
            });
          }
        }

        if (result.action == "addImagesFolder" && result.folder) {
          this.folderName = this.bussinessID + "/" + "images/" + result.folder;

          this.cloudinaryService
            .addImagesSubfolder(this.folderName)
            .subscribe((res22) => {
              let newItem = {
                name: result.folder,
                path: this.folderName,
              };
              this.imagesFolderArr.push(newItem);
            });
        }

        if (result.action == "addVideosFolder" && result.folder) {
          this.folderName = this.bussinessID + "/" + "videos/" + result.folder;
          // console.log("this.folderName>>>>> ", this.folderName);
          this.cloudinaryService
            .addVideosSubfolder(this.folderName)
            .subscribe((res22) => {
              let newItem = {
                name: result.folder,
                path: this.folderName,
              };
              this.videosFolderArr.push(newItem);
            });
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  deleteImage(public_id) {
    // console.log(public_id);
    this.openDialog({ action: "deleteImage", id: public_id, res: null });
  }

  deleteVideo(public_id) {
    // console.log(public_id);
    this.openDialog({ action: "deleteVideo", id: public_id, res: null });
  }

  openImage(image) {
    // console.log(image);
    this.openDialog({ action: "openImage", image: image });
  }

  openVideo(video) {
    // console.log(video);
  }
}
