import { Component, OnInit, ViewChild, ViewChildren, QueryList } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { CampaignService } from "src/app/services/campaign/campaign.service";
import { CampaignComponent } from "./../../components/campaigns/campaign/campaign.component";
import { AdService } from "src/app/services/ad/ad.service";
import { CampaignList } from "src/app/models/campaign.model";
import { PreviewComponent } from "./../../components/campaigns/preview/preview.component";

import {
  faFolderOpen,
  faFolder,
  faCloudUploadAlt,
  faTrashAlt,
  faFolderPlus,
  faSearch,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {
  MqttService,
  IMqttServiceOptions,
  MQTT_SERVICE_OPTIONS,
  IMqttMessage,
  MqttConnectionState,
} from "ngx-mqtt";
import { Subscription } from "rxjs";

@Component({
  selector: "app-campaigns",
  templateUrl: "./campaigns.component.html",
  styleUrls: ["./campaigns.component.css"],
})
export class CampaignsComponent implements OnInit {
  private subscription: Subscription;
  public message: string = "Mqtt message here!";

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  
  panelOpenState = false;
  campaigns: any;
  public campaign: any = { name: "" };
  playlistFrmCtrl = new FormControl();
  screenGroupsFrmCtrl = new FormControl();

  campaignId: any;
  public editMode: boolean = false;
  faFolderOpen = faFolderOpen;
  faFolder = faFolder;
  faCloudUploadAlt = faCloudUploadAlt;
  faTrashAlt = faTrashAlt;
  faFolderPlus = faFolderPlus;
  faSearch = faSearch;
  faChevronCircleRight = faChevronCircleRight;
  playlists: any;
  previewList: any[] = [];
  screenGroups: any;
  selectedPlaylist: any;
  selectedScreenGroups: any;
  data: any;
  startDate: any;
  stopDate: any;

  playlistsSelection: string[];
  screenGroupsSelection: string[];

  num_of_campaigns: number;
  num_of_playlists: number;
  num_of_screenGroups: number;

  showLoading = true;
  pageSizeOptions: number[] = [];
  displayedColumns: string[] = [
    "createDate",
    "name",
    "dateStart",
    "dateEnd",
    "lastPublish",
    "actions",
  ];
  dataSource: any;

  isLinear: boolean = false;
  favoriteSeason: string;
  seasons: string[] = ["Winter", "Spring", "Summer", "Autumn"];
  addMode: boolean = false;
  filter = "";

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private _mqttService: MqttService,
    private campaignService: CampaignService,
    private adService: AdService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filter = "";

    this.campaignService.getCampaignsList().subscribe(
      (res) => {
        if (res) {
          this.campaigns = res;
          this.num_of_campaigns = this.campaigns.length;
          this.createDataSource();
        }
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
        this.showLoading = false;
      }
    );

    this.adService.getDisplayList().subscribe(
      (res) => {
        this.playlists = res;
        this.num_of_playlists = this.playlists.length;
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
        this.showLoading = false;
      }
    );

    this.campaignService.getScreenGroups().subscribe(
      (res) => {
        this.screenGroups = res;
        this.num_of_screenGroups = this.screenGroups.length;
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
        this.showLoading = false;
      }
    );
  }

  createDataSource() {
    let campaignList: CampaignList["result"] = [];

    campaignList = this.campaigns;

    const ELEMENT_DATA: CampaignList["result"] = campaignList;
    this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort.toArray()[0];

    // console.log(this.dataSource.paginator,this.dataSource.sort );


    // this.orderData('screenId', 'desc')

    if (campaignList.length > 24)
      this.pageSizeOptions = [12, 24, campaignList.length];

    this.showLoading = false;
  }

  selectPlaylist(p) {
    this.selectedPlaylist = this.playlists[
      this.playlists.map((e) => e._id).indexOf(p)
    ];
  }

  newCampaign() {
    this.addMode = true;
    this.selectedPlaylist = "";
    this.showLoading = true;

    this.campaign = {};
    this.campaign.name = "New Campaign";

    this.campaign.createDate = moment().format("DD-MM-YYYY");

    this.campaign.dateStart = moment().format("DD-MM-YYYY");

    this.campaign.dateEnd = moment().format("DD-MM-YYYY");

    this.campaign.playlists = [];
    this.campaign.screenGroups = [];
    this.playlistsSelection = [];
    this.screenGroupsSelection = [];
    this.startDate = moment(this.campaign.dateStart, "DD-MM-YYYY");
    this.stopDate = moment(this.campaign.dateEnd, "DD-MM-YYYY");
    
this.campaign.schedule = [
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Δευτέρα",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Τρίτη",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Τετάρτη",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Πέμπτη",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Παρασκευή",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Σάββατο",
        enabled: true,
      },
      {
        lapses: [
          {
            start: "0:0",
            stop: "23:59",
          },
        ],
        day: "Κυριακή",
        enabled: true,
      },
    ];

    // this.campaignService.newCampaign(this.campaign).subscribe((res) => {

    //   this.campaignService.getCampaignsList().subscribe(
    //     (res) => {
    //       this.campaigns = res;
    //       this.num_of_campaigns = this.campaigns.length;
    //       this.createDataSource();
    //       this.showLoading = false;
    //       this.editMode = false;
    //     },
    //     (error) => {
    //       this.showLoading = false;
    //     }
    //   );
    // });
    this.showLoading = false;
  }

  editCampaign(id) {
    this.showLoading = true;

    this.campaignService.getCampaingById(id).subscribe(
      (res) => {
        this.campaign = res;
        // console.log("this.campaign", this.campaign);
        this.campaignId = this.campaign._id;
        delete this.campaign._id;
        this.playlistsSelection = [];
        this.campaign.playlists.forEach((element) => {
          // console.log("playlists", element);
          this.playlistsSelection.push(element._id);
        });
        this.screenGroupsSelection = [];
        this.campaign.screenGroups.forEach((element) => {
          // console.log("screenGroups", element);
          this.screenGroupsSelection.push(element._id);
        });
        // console.log("screenGroupsSelection >>", this.screenGroupsSelection);

        // this.startDate = new FormControl(moment(this.campaign.startDate).format());
        this.startDate = moment(this.campaign.dateStart, "DD-MM-YYYY");
        this.stopDate = moment(this.campaign.dateEnd, "DD-MM-YYYY");
        // this.startDate = new Date(this.campaign.dateStart);
        // this.stopDate = new Date(this.campaign.dateEnd);
        this.showLoading = false;
        this.editMode = true;
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
        this.showLoading = false;
      }
    );
  }

  onDeleteCampaign(id) {
   // this.showLoading = true;

    this.openDialog({ action: "deleteCampaign", id: id, res: null });

    // this.campaignService.deleteCampaing(id).subscribe((res) => {
    //   this.campaignService.getCampaignsList().subscribe(
    //     (res) => {
    //       this.campaigns = res;
    //       this.num_of_campaigns = this.campaigns.length;
    //       this.createDataSource();
    //       this.showLoading = false;
    //       this.editMode = false;
    //     },
    //     (error) => {
    //       this.showLoading = false;
    //     }
    //   );
    // });
  }

  openDialog(data): void {
    let w = "50%";
    if (data.action == "deleteCampaign") {
      w = "unset";
    }
    const dialogRef = this.dialog.open(CampaignComponent, {
      width: w,
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      this.showLoading = false;
      if (result.res == "ok") {
        this.showLoading = true;

        this.campaignService.deleteCampaing(data.id).subscribe((res) => {
          this.campaignService.getCampaignsList().subscribe(
            (res) => {
              this.campaigns = res;
              this.num_of_campaigns = this.campaigns.length;
              this.createDataSource();
              this.showLoading = false;
              this.editMode = false;
            },
            (error) => {
              this.campaigns = [];

              this.num_of_campaigns = this.campaigns.length;
              this.createDataSource();
              this.showLoading = false;
              this.editMode = false;            }
          );
        });
      }
    });
  }

  onChange(e) {
    //this.selectedScreenGroups = e.selectedOptions.selected.map(item => item.value);
  }

  closeCampaign() {
    this.editMode = false;
    this.addMode = false;
  }

  saveCampaign() {
    this.showLoading = true;

    if (this.addMode) {
      this.campaignService.newCampaign(this.campaign).subscribe((res) => {
        this.campaignService.getCampaignsList().subscribe(
          (res) => {
            this.campaigns = res;
            this.num_of_campaigns = this.campaigns.length;
            this.createDataSource();
            this.showLoading = false;
            this.editMode = false;
            this.addMode = false;
          },
          (error) => {
            this.showLoading = false;
          }
        );
      });
    }

    if (this.editMode) {
      this.campaign.dateStart = this.startDate.format("DD-MM-YYYY");
      this.campaign.dateEnd = this.stopDate.format("DD-MM-YYYY");

      this.campaignService
        .putCampaign(this.campaignId, this.campaign)
        .subscribe(
          (res) => {
            this.campaign = res;

            this.campaignService.getCampaignsList().subscribe(
              (res) => {
                this.campaigns = res;
                this.num_of_campaigns = this.campaigns.length;
                // create the users table data source
                // 7 is option for all users
                this.createDataSource();
                this.showLoading = false;
                this.editMode = false;
                this.addMode = false;
              },
              (error) => {
                // console.error("error caught in component >>>>>>>>> ", error);
                this.showLoading = false;
              }
            );
          },
          (error) => {
            // console.error("error caught in component >>>>>>>>> ", error);
            this.showLoading = false;
          }
        );
    }
  }

  deleteGroupFromList(i) {
    this.campaign.screenGroups.splice(i, 1);

    // this.campaignService.putCampaign(this.campaignId, this.campaign).subscribe((res) => {
    // });
  }

  changePlaylists(e) {
    this.campaign.playlists = [];
    e.forEach((element) => {
      this.campaign.playlists.push(
        this.playlists.find((x) => x._id == element)
      );
    });
  }

  changeScreenGroups(e) {
    this.campaign.screenGroups = [];
    e.forEach((element) => {
      this.campaign.screenGroups.push(
        this.screenGroups.find((x) => x._id == element)
      );
    });
  }

  scheduleChanged(idx) {
    this.campaign.schedule[idx].enabled = !this.campaign.schedule[idx].enabled;
    //console.log(this.campaign.schedule);
  }

  timeStartSetData(e, i) {
    // consol.log(i, e);
  }

  onDeleteLapse(schedule, lapse, idx, lapseIdx) {
    // consol.log(schedule, lapse, idx, lapseIdx);
    // this.campaign.schedule[idx].enabled = !this.campaign.schedule[idx].enabled
    this.campaign.schedule[idx].lapses.splice(lapseIdx, 1);
  }

  addLapse(schedule, idx) {
    // consol.log(schedule, idx);
    let l = { start: "0:0", stop: "23:59" };
    this.campaign.schedule[idx].lapses.push(l);
  }

  onPublishCampaign(row) {
    // console.log("PublishCampaign >>>>>>> ", row);

    // console.log(Date.now());

    this.campaignService.publishCampaign(row._id, Date.now()).subscribe(
      (res) => {
        // console.log("Publish Campain", res);
        this.ngOnInit();
      },
      (error) => {
        // console.error("error caught in component >>>>>>>>> ", error);
        this.showLoading = false;
      }
    );

    this.previewList = [];
    // console.log("Preview row :", row);
    row.playlists.forEach((element) => {
      // console.log(element);
      //this.previewList.push(
      this.playlists
        .find((x) => x._id == element)
        .list.forEach((element1) => {
          // console.log(">>>>>>>>> ", element1);
          this.previewList.push(element1);
        });
      //);
    });
    // console.log('############# ### ## # ',this.previewList);
    row.screenGroups.forEach((element) => {
      this.unsafePublish(
        sessionStorage.getItem("businessId")+"/" + element,
        JSON.stringify(this.previewList)
      );
    });
  }

  onPreviewCampaign(row) {
    this.previewList = [];
    // consol.log("Preview row :", row);
    row.playlists.forEach((element) => {
      // consol.log(element);
      //this.previewList.push(
      this.playlists
        .find((x) => x._id == element)
        .list.forEach((element1) => {
          // consol.log(element1);
          this.previewList.push(element1);
        });
      //);
    });
    // consol.log(this.previewList);

    const dialogRef = this.dialog.open(PreviewComponent, {
      width: "1020px",
      height: "630px",
      data: { previewList: this.previewList },
      panelClass: "myapp-no-padding-dialog",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // consol.log("The preview dialog was closed :", result);
    });
  }

  public unsafePublish(topic: string, message: string): void {
    // console.log("topic : ",topic);
    // console.log("message : ",message);
    this._mqttService.unsafePublish(topic, message, { qos: 2, retain: true });
  }
}
