import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  url: string = 'http://localhost:3000/api/';
  apiUrl: string = environment.general_url;
  // url: string = 'https://smartanasignage.herokuapp.com/api/';
  // url: string = 'https://coffeelabsignage.herokuapp.com/api/';

  constructor(
    private http: HttpClient
  ) { }

  signUpWithEmail(email: string, password: string): Observable<any> {

    let data = { "email": email, "password": password };

    return this.http.post<any>(this.apiUrl + "signup", JSON.stringify(data), httpOptions);
    // .pipe(
    //   catchError(this.handleError)
    // );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `STATUS ${error.status}: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      error);
  };
}