import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  uid: string;
  user = [];
  isAdmin: boolean = true;
  
  
  // showHideBoolean: boolean = true;
  @Output() show: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() hide: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(
    private translate: TranslateService) { }

  ngOnInit() {

    this.uid = localStorage.getItem('uid');

    const defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(defaultLang);
  }

  showElement(){
    this.show.emit(true);
  }

  hideElement(){
    this.hide.emit(false);
  }
}
