import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthguardService } from './services/authguard/authguard.service';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { ScreensComponent } from './components/screens/screens.component';
import { AdsComponent } from './components/ads/ads.component';
import { HomeComponent } from './components/home/home.component';
import { ContentComponent } from './components/content/content.component';
import { InstantComponent } from './components/instant/instant.component';
import { RegisterComponent } from './components/register/register.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthguardService],
    children: [
      // { path: 'home',    redirectTo: '', pathMatch: 'full' },
      { path: 'home',    component: HomeComponent },
      { path: 'campaigns',    component: CampaignsComponent },
      { path: 'screens',    component: ScreensComponent },
      { path: 'ads',    component: AdsComponent },
      { path: 'content',    component: ContentComponent },
      { path: 'instant',    component: InstantComponent }
    ]
  },
  { path: 'login',    component: LoginComponent },
  { path: 'register', component: RegisterComponent },

  { path: '',         component: LoginComponent },
  { path: '**',       component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
