import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import {
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit() {
  // console.log(this.data);

}

onNoClick(): void {
  this.dialogRef.close(this.data);
}

deletePlaylist() {
  this.data.res = 'ok';
  this.dialogRef.close(this.data);
}

addYoutubeToPlaylist() {
  this.data.res = 'ok';
  //this.data.youtubeUrl = 'https://www.youtube.com/watch?time_continue=7&v=cOK7UMhAfYE'
  this.dialogRef.close(this.data);
}

addWebToPlaylist() {
  this.data.res = 'ok';
  //this.data.youtubeUrl = 'https://www.youtube.com/watch?time_continue=7&v=cOK7UMhAfYE'
  this.dialogRef.close(this.data);
}

addVimeoToPlaylist() {
  this.data.res = 'ok';
  //this.data.youtubeUrl = 'https://www.youtube.com/watch?time_continue=7&v=cOK7UMhAfYE'
  this.dialogRef.close(this.data);
}


}
