import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ScreenService } from "src/app/services/screen/screen.service";
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  // displayDashboardHome
  message: string;
  title: string;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private screenService: ScreenService,
    private translate: TranslateService
  ) {}

  signInWithEmail(email: string, password: string) {
    console.log(email, password);

    this.screenService.signInWithEmailAndPassword(email, password).subscribe(
      res => {
        console.log("HTTP response >>>> ", res);
        let token = jwt_decode(res);
        console.log("Token >>>>>>>>>>", token);

        sessionStorage.setItem("userId", token._id);
        sessionStorage.setItem("businessId", token.businessId);
        sessionStorage.setItem("token", res);

        // localStorage.setItem("uid", res);
        // localStorage.setItem("email", email);
        sessionStorage.setItem("isLoggedIn", "true");
        this.router.navigate(["dashboard/home"]);
      },
      err => {
        if (err.message.includes("The user account has been disabled")) {
          this.message = this.translate.instant("LOGIN.LOGIN_FAILED");
          this.title = this.translate.instant("TITLE.ERROR");

          this.toastr.error(this.message, this.title);
        } else if (err.message.includes("There is no user record")) {
          this.message = this.translate.instant("LOGIN.LOGIN_NO_USER");
          this.title = this.translate.instant("TITLE.ERROR");

          this.toastr.error(this.message, this.title);
        } else {
          this.message = this.translate.instant("LOGIN.LOGIN_NO_USER");
          this.title = this.translate.instant("TITLE.ERROR");

          this.toastr.error(this.message, this.title);
        }
      },
      () => console.log("HTTP request completed.")
    );
  }
}
